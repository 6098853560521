<template>
  <div class="sales-activity-edit">
    <div v-loading="loading" />
    <template v-if="!loading">
      <RewardActivityInfoBlock
        class="card-container form-wrapper"
        :FormsContext="FormsContext"
        :activityData="activityData"
      />
      <RewardActivityProductBlock
        class="card-container form-wrapper"
        :FormsContext="FormsContext"
        :activityData="activityData"
        :storeProducts="storeProducts"
        :selectProductConfig="selectProductConfig"
        :productCategoryConfig="productCategoryConfig"
      />
      <RewardActivityTypeBlock
        class="card-container form-wrapper"
        :FormsContext="FormsContext"
        :typeSettingConfig="typeSettingConfig"
        :changeTypeHandler="changeTypeHandler"
        :activityData="activityData"
      />
      <RewardActivityRulesBlock
        class="card-container form-wrapper"
        :FormsContext="FormsContext"
        :rewardType="rewardType"
        :activityData="activityData"
      />
      <RewardActivityLimitBlock
        class="card-container form-wrapper"
        :FormsContext="FormsContext"
        :rewardType="rewardType"
        :activityData="activityData"
      />
    </template>

    <PageFixedFooter @cancel="onCancel" @confirm="onSubmit" />

    <WarningDialog
      v-if="dialog.show"
      title="提醒"
      width="560px"
      :content="dialog.content"
      hideCancel
      @confirm="dialog.show = false"
      @close="dialog.show = false"
    />
  </div>
</template>

<script>
import { defineComponent, ref, reactive, computed, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router/composables'
import store from '@/store'
import PageFixedFooter from '@/components/Footer/PageFixedFooter.vue'
import RewardActivityInfoBlock from '@/components/RewardActivity/RewardActivityInfoBlock.vue'
import RewardActivityTypeBlock from '@/components/RewardActivity/RewardActivityTypeBlock.vue'
import RewardActivityRulesBlock from '@/components/RewardActivity/RewardActivityRulesBlock.vue'
import RewardActivityLimitBlock from '@/components/RewardActivity/RewardActivityLimitBlock.vue'
import RewardActivityProductBlock from './components/SalesActivityProductBlock.vue'
import WarningDialog from '@/components/Dialog/WarningDialog.vue'
import FormsContext from './formsContext'
import {
  CreateRewardActivity,
  FindRewardActivity,
  UpdateRewardActivity,
  GetSalesProduct,
  GetSalesProductCount,
} from '@/api/rewardActivity'
import { getAllDataFromApi } from '@/utils/helper'
import { usePermissions } from '@/use/permissions'
import { filter } from 'lodash'

export default defineComponent({
  name: 'SalesActivityEdit',
  components: {
    PageFixedFooter,
    RewardActivityInfoBlock,
    RewardActivityTypeBlock,
    RewardActivityRulesBlock,
    RewardActivityLimitBlock,
    RewardActivityProductBlock,
    WarningDialog,
  },
  setup () {
    const router = useRouter()
    const route = useRoute()
    const shopId = computed(() => store.getters.shop)
    const activityId = computed(() => route.params.id)
    const activityData = ref(null)
    const loading = ref(false)
    const storeProducts = ref([])
    const dialog = reactive({
      show: false,
      content: '',
    })

    const onCancel = () => {
      router.push({
        name: 'SalesActivity',
      })
    }

    const typeSettingConfig = [
      {
        label: '點數',
        value: 'POINT',
        featureKey: 'admin.shopPoint.page',
      },
      {
        label: '回饋金',
        value: 'CASHBACK',
        featureKey: 'admin.shopCashback.page',
      },
    ]

    const selectProductConfig = [
      {
        label: '全部商品 (可選擇排除部分商品)',
        value: 'EXCLUDE',
        chooseLabel: '選擇排除商品',
      },
      {
        label: '只針對特定商品',
        value: 'INCLUDE',
        chooseLabel: '選擇特定商品',
      },
      {
        label: '指定商品類別',
        value: 'category',
        chooseLabel: '',
      },
    ]

    const productCategoryConfig = [
      {
        label: '無',
        value: null,
        chooseLabel: '',
      },
      {
        label: '排除特定商品',
        value: 'EXCLUDE',
        chooseLabel: '選擇排除商品',
      },
      {
        label: '加上特定商品',
        value: 'INCLUDE',
        chooseLabel: '選擇特定商品',
      },
    ]

    const { checkAction } = usePermissions()

    const rewardTypeOptions = computed(() => {
      return filter(typeSettingConfig, (option) => checkAction(option.featureKey))
      // return props.typeSettingConfig
    })

    const rewardType = ref(rewardTypeOptions.value[0].value)
    const changeTypeHandler = (data) => {
      rewardType.value = data
    }

    const findActivity = async () => {
      loading.value = true
      const [res, err] = await FindRewardActivity({
        shopId: shopId.value,
        id: activityId.value,
      })
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }
      activityData.value = res
      rewardType.value = res.rewardType
    }

    const createRewardActivity = async (payload) => {
      loading.value = true
      const [, err] = await CreateRewardActivity({
        shopId: shopId.value,
        postBody: {
          ...payload,
        },
      })
      if (err) throw err
      window.$message.success('新增成功!')
    }

    const updateRewardActivity = async (payload) => {
      loading.value = true
      const [, err] = await UpdateRewardActivity({
        shopId: shopId.value,
        id: activityId.value,
        postBody: {
          ...payload,
        },
      })
      if (err) throw err
      window.$message.success('更新商品成功！')
    }

    const checkProduct = (data) => {
      const { sourceItemsSettings } = data
      const categorySetting = sourceItemsSettings.find(({ sourceItemType }) => {
        return sourceItemType === 'salesCategory'
      })
      const productSetting = sourceItemsSettings.find(({ sourceItemType }) => {
        return sourceItemType === 'salesProduct'
      })
      if (categorySetting && categorySetting.sourceItemIds.length <= 0) {
        dialog.show = true
        dialog.content = '選擇「指定商品類別」進行兌換活動請至少選擇一項指定類別'
        return false
      }
      if (productSetting && productSetting.operator === 'EXCLUDE') {
        return true
      }
      if (productSetting && productSetting.sourceItemIds.length <= 0) {
        dialog.show = true
        dialog.content = '選擇「只針對特定商品」進行兌換活動請至少選擇一項特定商品'
        return false
      }
      return true
    }

    const onSubmit = async () => {
      const allPass = await FormsContext.validateAll()
      if (allPass) {
        const data = FormsContext.composFormData()
        const postBody = {
          ...data,
          source: 'SALE_RECORD',
        }
        if (!checkProduct(postBody)) return
        try {
          if (!activityId.value) await createRewardActivity(postBody)
          if (activityId.value) await updateRewardActivity(postBody)
          router.push({ name: 'SalesActivity' })
        } catch (error) {
          window.$message.error(error)
          loading.value = false
        }
      }
    }

    const getProductCount = async () => {
      const [res, err] = await GetSalesProductCount({ shopId: shopId.value })
      if (err) throw err
      return res.count
    }

    // 取得所有商品
    const getAllStoreProducts = async () => {
      let max
      try {
        max = await getProductCount()
      } catch (error) {
        window.$message.error(error)
        return
      }

      const config = {
        shopId: shopId.value,
        start: 0,
        limit: 100,
      }
      const [res, err] = await getAllDataFromApi(
        max,
        GetSalesProduct,
        config,
        true,
      )
      if (err) return window.$message.error(err)
      storeProducts.value = res
    }

    onMounted(async () => {
      await getAllStoreProducts()
      if (activityId.value) await findActivity()
    })

    return {
      onCancel,
      onSubmit,
      loading,
      FormsContext,
      typeSettingConfig,
      changeTypeHandler,
      rewardType,
      activityData,
      storeProducts,
      selectProductConfig,
      productCategoryConfig,
      dialog,
    }
  },
})
</script>

<style lang="postcss" scoped>
.sales-activity-edit {
  @apply flex flex-col gap-[20px];
}
.form-wrapper {
  @apply px-[32px] py-[24px];
}
</style>
