<template>
  <el-select
    v-model="syncModel"
    :multiple="multiple"
    value-key="id"
    placeholder="請選擇銷售類別"
    no-data-text="暫無數據"
    collapse-tags
    @change="$emit('change', salesCategoryList)"
  >
    <el-option
      v-for="item in salesCategoryList"
      :key="item.id"
      :label="item.name"
      :value="item"
    />
  </el-select>
</template>

<script>
import { GetSalesCategory, GetSalesCategoryCount } from '@/api/sales'
import { mapGetters } from 'vuex'
export default {
  name: 'SalesCategorySelect',
  props: ['model', 'multiple', 'showAll'],
  computed: {
    ...mapGetters([
      'shop',
    ]),
    syncModel: {
      get () {
        return this.model
      },
      set (data) {
        this.$emit('update:model', data)
      },
    },
  },

  data: () => ({
    salesCategoryList: [],
  }),

  async mounted () {
    if (this.showAll) {
      await this.getAllSalesCategory()
      return
    }
    await this.getAllSalesCategory()
  },

  methods: {
    async getAllSalesCategory () {
      try {
        const max = await GetSalesCategoryCount({ shopId: this.shop })
        const options = {
          shopId: this.shop,
          start: 0,
          limit: 100,
        }
        const res = await GetSalesCategory(options)
        console.log(res)
        this.salesCategoryList = res
        while (this.salesCategoryList.length < max) {
          options.start += 100
          const res = await GetSalesCategory(options)
          this.salesCategoryList.push(res)
        }
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>

<style scoped lang="scss"></style>
